import React, { Component } from 'react';
import './_videos.scss'
import BodyClassName from 'react-body-classname';

class Videos extends Component {
    static defaultProps = {      
        youtubeLinks:["RPW_dS313A0", "IXg7QZI8btQ" ,"m-MjMQqfaFw"]
    } 
    render(){
        const youtubeLinks = this.props.youtubeLinks.map((val)=>(
            <div className="video-frame">
                <iframe title="A Message from Javier Smith Paterson" width="560" height="315" src={`https://www.youtube.com/embed/${val}`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
        ))
        return(
            <>
                <BodyClassName className="video-page" />
                <h1>Videos</h1>                
                <div className="container video-list">                                        
                    {youtubeLinks}
                </div>     
            </>
        )    
    }       
}
export default Videos;