import React, { Component } from 'react';
import BodyClassName from 'react-body-classname';
import './_home.scss'
import Banner from '../../layout/sections/banner/Banner';
import InvestmentItem from '../investments/InvestmentItem';


class Home extends Component{
    
    linker(str){
        return str.toString().toLowerCase().split(" ").join("-").replace(/[^a-zA-Z0-9 -]/g, '');
    }
    render(){        
        window.scrollTo(0, 0);                
        const investments = this.props.propertyData.map((val, idx) => (
            <InvestmentItem
                key={idx}
                idx={idx}
                link={this.linker(val.title)} 
                title={val.title}
                subTitle={val.field_sub_title}
                category={val.field_category}
                location={val.field_location}
                area={val.field_size}
                price={val.field_price}
                image={val.field_image}
                thumb={val.field_image_1}
                thumbnail={val.field_thumbnail}
            />
        ))
        const banner = this.props.propertyData.map((val, idx) => (
            val.promote === "On" ?
              <Banner title="Real Estate Investments" subTitle="the only way to achieve freedom with a beachfront property in Panama" showContactForm={this.props.showContactForm} />
            : null
        ))        
        return(
            <>            
                <BodyClassName className="front" />
                { banner }
                <section id="investments" className="investmentsList">                         
                    <h2>Investments</h2>
                    <div className="property-block container">              
                    {investments}
                    </div>
                </section>
            </>
        )
    }
}

export default Home;