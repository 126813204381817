import React, { Component } from 'react';
import { NavLink, Link} from 'react-router-dom'
import MobileLink from './MobileLink';
import './_navigation.scss';


class Navigation extends Component{
    static defaultProps = {
        urlLinks: ["Home", "Investments", "Videos", "About Us", "Contact"]
    }
    linker(str){ //to remove special characters, capitals and spaces from titles and become links
        return str.toString().toLowerCase().split(" ").join("-").replace(/[^a-zA-Z0-9 -]/g, '');
    }
    showModal = () => {                
        document.querySelector('html').classList.add('scroll-lock'); //lock the screen when on mobile modal or contact modal
        document.querySelector('#wrapper').classList.add('mobile'); // add mobile class to wrapper div
        document.querySelector('nav').focus();        //focus the opened element
     //   this.toggleScrollLock();
    };
    closeModal = () => {      
        this.toggleScrollLock();                //return classes to normal
        document.querySelector('#wrapper').classList.remove('mobile');            
    };    
    //contact form display
    contactModal = () => {      
        this.props.showContactForm();                //return classes to normal                
        document.querySelector('html').classList.add('scroll-lock');
        document.querySelector('#wrapper').classList.remove('mobile');            
        
    };  
    toggleScrollLock = () => {
        document.querySelector('html').classList.remove('scroll-lock');
    };
    render(){        
        const navItem = this.props.urlLinks.map((val, idx) => (            
            <li key={idx}>
                {   //homepage link //so it  takes you to "/" and not to "/home"
                    val === "Home" ? <Link tabIndex="2" onClick={this.closeModal} to="/"className={`${val}-${idx}`} >{val}</Link>       
                    :val === "About Us" ? <NavLink tabIndex="2" onClick={this.closeModal} to="/about" className={`${val}-${idx}`}>{val}</NavLink>                
                    :val === "Contact" ? <a href="#" tabIndex="2" onClick={this.contactModal} className={`${val}-${idx}`}>{val}</a>  //contact link for contact form modal
                    : <NavLink tabIndex="2" to={`/${this.linker(val)}`} onClick={this.closeModal} className={`${val}-${idx}`}>{val}</NavLink> //rest of link
                  }                                
            </li>
        ))
        return (
            <nav role="navigation" aria-label="Main Navigation">
                <MobileLink  showModal={this.showModal} closeModal={this.closeModal} />
                <ul id="links">
                    {navItem}                    
                </ul>
            </nav>
        )
    }    
}
export default Navigation;