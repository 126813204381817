import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Img from 'react-image';
import Spinner from '../../layout/general/spinner/Spinner';

class InvestmentItem extends Component {             
    render(){            
        return (
            <div className={`main-block block investment-item property-${this.props.idx}`}>
                <Link tabIndex="3" to={`investments/property/${this.props.idx}/${this.props.link}`}>                    
                    { this.props.thumbnail ? 
                        <Img loader={<Spinner />} src={`https://jsprealtor.com/${this.props.thumbnail}`} alt={this.props.title} />
                    :    <Img loader={<Spinner />} src={`https://jsprealtor.com/${this.props.thumb}`} alt={this.props.title} />
                    }                    
                    <h3>{this.props.title}<span>{this.props.subTitle}</span></h3>
                    <div className="block-img-txt">                                
                        <p><span className="label">Size:</span> {this.props.area}</p>
                        <p ><span className="label">Location:</span> {this.props.location}</p>
                        <div className="btn">More</div>
                    </div>
                </Link>
            </div>
        )   
    }
}

export default InvestmentItem