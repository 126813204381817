import React, { Component } from 'react';
import BodyClassName from 'react-body-classname';
import Banner from '../../layout/sections/banner/Banner';
import './_about.scss'

class About extends Component{
    
    render(){        
        window.scrollTo(0, 0);   
        document.title = "About JSPrealtor.com | Javier Smith Paterson Real Estate Investor and Investment Opportunities";        
        return(
            <>
                <BodyClassName className="about" />                                
                <Banner title="About JSPrealtor.com" showContactForm={this.props.showContactForm} />                  
                <div className="container"> 
                    <div className="flex" style={{margin:"20px 0 0 0"}}>
                        <div style={{margin:"0 10px 0 0", justifyContent:"justify"}}>
                            <h2><span>A Message from </span><br />Javier Smith Paterson</h2>
                            <p>I believe Real Estate is the ultimate goal for a succesful person, learning how to buy, sell or invest was not easy, but now we have the tools and the people to do it. I was always amazed but the huge amount of raw land we always had in Panama but I didn't realize how valuable it was until I moved to Europe.</p>
                            <p>In a strategic partnership with Jose Goldner and TCP (Tuna Coast Properties), our investors have recived returns from 3x to 20x on original invested dollars over a period of 5 - 12 years. Our network has invested in Panama real estate for many years with excelllent results.</p>
                        </div>
                        <div className="video">
                            <iframe title="A Message from Javier Smith Paterson" width="560" height="315" src="https://www.youtube.com/embed/RPW_dS313A0?controls=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>                    
                    <p>Working and participating directly with the community, We work directly with the local farmers and ranchers that have no access to an international market of investors, that gives us access to front-beach properties under market prices.</p>
                    <p>Our partnership put our offices in Panama, Boston and Brussels which makes us the perfect platform to ensure capacity, transparency and accounstability for international investors/buyers.</p>                    
                    <h3>Unique Investment Characteristhics</h3>
                    <p>When JSPrealtor invests in a property JSPrealtor buys at a MINIMUM 30 percent discount below appraised value. Generally the land acquired needs landscaping and upgrading to determine use of land or sales at retail pricing. JSPrealtor brings regional experienced teams of architects and engineers to look carefully at the land and determine the factors to consider when JSPrealtor decides to sell the property in the future. JSPrealtor is very careful to buy properties that have unique characteristics such as unblocked views, plenty of water supply, no neighbors, plenty of green area around lots with no adjacent building allowed.</p>                               
                </div>
            </>
        )
    }
}

export default About;