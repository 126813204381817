import React, { Component } from 'react';
import './_contactInfo.scss'
//Images
import BeFlag from '../../../../images/icons/be-flag-32.png' //belgium flag

class ContactInfo extends Component { 
    static defaultProps = {
        info:{
            email:'info@jsprealtor.com', 
            phone:'(+32) 487-727-689',           
        }
    }
    defaultStyle = {
        width: "15px",
        verticalAlign: "middle"
    }    
    render(){                
        return( 
            <React.Fragment>
                 <div className="social-contact">                     
                     <span className="hidden">Contact Information: </span>
                     <ul>
                         <li>
                            <a className="phone" href={`tel:${this.props.info.phone}`} tabIndex="4">                         
                                <span className="hidden">Mobile Phone</span>
                                <img alt="Belgium Flag" role="presentation" style={this.defaultStyle} src={BeFlag} />
                                <i className="fas fa-phone" aria-hidden="true"></i><span className="only-desktop">{this.props.info.phone}</span>
                            </a>        
                         </li>
                         <li>
                         <a href={`mailto:${this.props.info.email}`} tabIndex="4">
                            <span className="hidden">E-mail Address</span>
                            <i className="fas fa-envelope" aria-hidden="true"></i>
                            <span className="only-desktop">{this.props.info.email}</span>
                            </a>
                         </li>
                     </ul>                                          
                 </div>         
            </React.Fragment>   
         )
    }     
}
export default ContactInfo;