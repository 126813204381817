//imported in Header.js
import React, { Component } from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import BodyClassName from 'react-body-classname';
import './_contact.scss';

class Contact extends Component  {
  constructor(props){
    super(props);
    this.state = {
        mailSent:false,
        errorData:null,
        requestData:'',
        confirm:false,
        submitted:false
    }                   
    this.handleSubmit = this.handleSubmit.bind(this);          
  }
  handleSubmit(e){
    e.preventDefault();
    this.setState({submitted:true});
    const name = e.target.name.value;
    const email = e.target.email.value;
    const mobile = e.target.mobile.value;
    const body = e.target.body.value;
    axios.get('https://jsprealtor.com/api/session/token').then(result => {		
      //console.log(result);
      if (200 === result.status) {
        const csrfToken = result.data;
        fetch('https://jsprealtor.com/api/webform_rest/submit?_format=json', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
          },
          body: JSON.stringify({
          'webform_id':'contact',
          'entity_type' : null,
          'entity_id' : null,
          'in_draft' : false,
          'uri' : ['/webform/contact/api'],
          'name' : name, 
          'email' : email,
          'subject' : mobile,
          'message' : body,
          }),
        })
            .then(response => response.json())
        .then(
          (data) => {
            //on success you do whatever you will want
            //console.log('success', data);
            this.setState({confirm:true, requestData:data})    
            setTimeout(() => {            
              this.setState({                                        
                confirm:false,
                submitted:false
              })      
              this.props.hideContactForm();
            }, 2000);  
          },
              (error) => {
                //console.log('error', error);
                this.setState({errorData:error})                
          }
        );
      }
    });
  }

  render(){     
    document.title = "Contact Us";       
    return ReactDOM.createPortal(    
      <div className="connect">
        <BodyClassName className="connect" />              
        <div className="container">
          <button onClick={this.props.hideContactForm} className="close"> <span className="hidden">Close contact form window</span> 
            X 
          </button> 

          {this.state.submitted === true ? 
            <div className="confirm-message">
              Thank you for contacting us, <br />We will get back to you very shortly
            </div> :
            //conctact form
            <div className="contact-form">                            
              <h1>Connect with Us
                <span className="hidden">contact form </span>
              </h1> 
              <form  onSubmit={this.handleSubmit}>              
                <div>
                  <label className="hidden" >Name:</label>
                  <input type="text" id="fname" name="name" placeholder="Your name.." />
                </div>              
                <div>
                  <label className="hidden">E-mail:</label>        
                  <input type="email" name="email" placeholder="example@email.com"  aria-required="true" required  />  
                </div>              
                <div>
                  <label className="hidden">Mobile Phone</label>
                  <input type="tel" name="mobile" placeholder="+33 789-999989"  />
                </div>              
                <div>
                  <label className="hidden" >How can we Help you?</label>
                  <textarea id="subject" name="body" placeholder="Write something.."></textarea>
                </div>              
                <button type="submit" className="submit button" value="Submit">Submit</button>
              </form>
            </div>    
          }               
        </div>                   
      </div>, document.body            
    )
  }
}

export default Contact
