import React from 'react';

const MobileLink = (props) =>{      
    return (
        <div className="icons mobile-trigger" >            
            <button onClick={props.showModal} id="toggle" className="open" aria-expanded="false" aria-controls="menu">
                <span className="hidden">open navigation</span>
                <span className="icon"  >☰</span>
            </button>  
            <button onClick={props.closeModal}  className="close" aria-expanded="false"  aria-controls="menu">
                <span className="hidden">Close navigation</span>
                <span className="icon">X</span>
            </button>  
        </div>
    )
}

export default MobileLink;