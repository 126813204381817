import React, { Component } from 'react';
import './_spinner.scss'

class Spinner extends Component {                    
    render(){        
        return (
            <>                    
              <div className="loading-spinner">
                <i className="fas fa-circle-notch fa-spin"></i>
                  <span>loading....</span>
                </div>
            </>                        
        )   
    }
}

export default Spinner
