import React, { Component } from 'react';
import InvestmentItem from './InvestmentItem';
import './_investments.scss';

class Investments extends Component {   
    linker(str){
      return str.toString().toLowerCase().split(" ").join("-").replace(/[^a-zA-Z0-9 -]/g, '');
    }
    render(){        
        const propertyItems  = this.props.propertyData.map((val, idx) =>(            
            <InvestmentItem 
                key={idx}
                idx={idx}
                link={this.linker(val.title)}
                title={val.title}
                subTitle={val.field_sub_title}
                image={val.field_image}
                thumbnail={val.field_thumbnail}
                thumb={val.field_image_1}
                area={val.field_size}
                location={val.field_location}
                price={val.field_price}
                
            />
        ))
        
        return (       
            <section id="investments" className="investmentsList">                         
                <h2>Investments</h2>
                <div className="property-block container">              
                    {propertyItems}
                </div>
            </section>
        )
    }    
}

export default Investments
