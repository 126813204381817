import React, { Component } from 'react';
import CTA from '../../general/call-to-action/CallToAction';
import './_banner.scss';

class Banner extends Component { 
    constructor(props){
        super(props);
        this.state = {
            bgImg:'/static-version/images/playanomada.jpg',
        }
    }
    render(){                   
        return(
            <section id="banner" style={{backgroundImage:`url("${this.state.bgImg}")`}}  >
                <MainData title={this.props.title} subTitle={this.props.subTitle} />
                <CTA text="Contact Us" showContactForm={this.props.showContactForm} />
            </section>
        )
    }
}
const MainData = (props) => {     
    return(
        <div className="main-data">
            <h1>{props.title}
                <span>{props.subTitle}</span>
            </h1>
        </div>
    )
}


                 
export default Banner