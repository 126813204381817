import React from 'react';
import './_cta.scss';

const CTA = (props) => {    
    return(
        <div className="cta">            
            <button onClick={props.showContactForm} className="btn" tabIndex="3">{props.text}</button>
            <a href="https://calendly.com/jsprealtor/investment-call" className="btn red" tabIndex="3">Schedule a Call</a>            
        </div>  
    )    
}
                 
export default CTA