import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import parse from 'html-react-parser';
import ReactHtmlParser from 'react-html-parser';
import BodyClassName from 'react-body-classname';
import CTA from '../../layout/general/call-to-action/CallToAction';


class InvestmentProperty extends Component{    
    constructor(props){
        super(props);
        this.state = {
            id:'',
            ttle:'',
            propertyData:[]
        }        
    }    
    componentDidMount(){                        
        const { params:{ propertyId, propertyTitle } } = this.props.match;           
        this.setState({propertyId, propertyTitle});
    }
    render(){        
        const investmentItem = this.props.propertyData.map((val, idx) => (
          idx == this.state.propertyId ?
            <PropertyItem 
                key={idx}
                showContactForm={this.props.showContactForm}
                title={val.title}
                subTitle={val.field_sub_title}
                category={val.field_category}
                area={val.field_size}
                price={val.field_price}
                location={val.field_location}
                mainImage={val.field_image}
                banner={val.field_image}
                shortBody={val.field_short_body}
                longBody={val.body}
                featuredBlock={val.field_featured_block}
                youtube={val.field_youtube}                            
            /> : null
      ))
      
      return(
        <>
            {investmentItem}
        </>
      )
    }   
  }

  class PropertyItem extends Component {
    render(){
        window.scrollTo(0, 0);
        document.title = `${this.props.title} | JSPrealtor.com`; 
      return(
        <>        
            <BodyClassName className={`investment-property  ${this.props.title}`} />    
            <section id="banner" style={{backgroundImage:`url('https://jsprealtor.com/${this.props.banner}')`}}>   
                <div className="container flex">
                    <div className="main-text">      
                        <h1 className="title">{this.props.title} 
                            <span className="short-description">{this.props.subTitle}</span>
                        </h1>
                    </div>
                    <div className="main-data">
                        <div className="item location">
                            <i class="fas fa-map-pin" aria-hidden="true"></i>
                            <span className="label">Location:</span> 
                            <span>{this.props.location}</span>
                        </div>
                        <div className="item price">
                            <i class="fas fa-dollar-sign" aria-hidden="true"></i>
                            <span className="label">Price:</span> 
                            <span>{this.props.price}</span>
                        </div>
                        <div className="item area">
                            <i class="far fa-map" aria-hidden="true"></i>
                            <span className="label">Lot's Area:</span> 
                            <span>{this.props.area}</span>
                        </div>
                        <div className="item contact">
                            <CTA text="Contact Us" showContactForm={this.props.showContactForm} />
                        </div>
                    </div>
                </div>                            
            </section>  
            <div id="main" className="block property-main-info">
                <div className="container">
                    <h2 >Welcome to {this.props.title}</h2>
                    { this.props.longBody ? <p className="full-description"> { parse(this.props.longBody) } </p>   : null  }
                    { this.props.featuredBlock ? <div className="row featured-data"> {ReactHtmlParser(parse(this.props.featuredBlock))} </div> : null}                    
                    <CTA text="Contact Us" showContactForm={this.props.showContactForm} />      
                    { this.props.youtube ?  <div className="youtube video">{ReactHtmlParser(parse(this.props.youtube))}</div> : null }                    
                </div>                                       
            </div> 
        </>        
      )
    }
  }
  
  export default withRouter(InvestmentProperty);