import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LogoImg from './logo.png'
import './_logo.scss'

class Logo extends Component {
    static defaultProps = {      
        text:'JSP Realtor Logo, go to homepage link'
    } 
    render(){
        return(
            <div id="logo" role="presentation">
                <Link to="/" tabIndex="1"> 
                    <img alt={this.props.text} src={LogoImg}/>
                </Link>
            </div>     
        )    
    }       
}
export default Logo;