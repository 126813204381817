import React, { Component } from 'react';
import './_socialMediaIcons.scss'
class SocialMediaIcons extends Component { 
    static defaultProps = {
        info:{
            email:'info@jsprealtor.com', 
            phone:'(+32) 487-727-689',
            facebook:'https://www.facebook.com/javiersmithpaterson/',
            instagram:'https://www.instagram.com/javiersmithbjj/',
            twitter:'https://twitter.com/jsmithp',
            linkedin:'https://www.linkedin.com/in/javiersmithpaterson/',
            youtube:'https://www.youtube.com/c/JavierSmithPaterson'            
        }
    }
    render(){
        return (
            <>
                <div className="social-icons">
                    <span className="hidden">Social Media Links</span>
                    <ul>
                        <li>                            
                            <a href={this.props.info.youtube} target="_blank" rel="noopener noreferrer" tabIndex="4">
                                <span className="hidden">Youtube</span>                                
                                <i className="fab fa-youtube" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li>
                            <a href={this.props.info.facebook}  target="_blank" rel="noopener noreferrer" tabIndex="4">
                            <span className="hidden">Facebook</span>
                                <i className="fab fa-facebook-f" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li>
                            <a href={this.props.info.linkedin} target="_blank" rel="noopener noreferrer" tabIndex="4">    
                                <span className="hidden">Linkedin</span>
                                <i className="fab fa-linkedin-in" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li>
                            <a href={this.props.info.instagram} target="_blank" rel="noopener noreferrer" tabIndex="4">
                            <span className="hidden">Instagram</span>
                            <i className="fab fa-instagram" aria-hidden="true"></i>
                            </a>    
                        </li>
                    </ul>
                </div>
            </>
        )
    }
    
}

export default SocialMediaIcons;