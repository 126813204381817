import React, { Component } from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import axios from 'axios';

import Home from './components/pages/home/Home';
import About from './components/pages/about/About';
import Videos from './components/pages/videos/Videos';
import Investments from './components/pages/investments/Investments';
import InvestmentProperty from './components/pages/investments/InvestmentProperty';


import Header from './components/layout/sections/header/Header';
import Footer from './components/layout/sections/footer/Footer';

import Contact from './components/forms/contact/Contact';




class App extends Component {  
  constructor(props){
    super(props);
    this.state = {
      investments:[],
      showContactForm:false
    }
  }

  showContactForm = () => {
      this.setState({showContactForm:true})
  };
  hideContactForm = () => {
      this.setState({showContactForm:false})
  };

  componentDidMount() {
    axios.get(`https://jsprealtor.com/api/property-data`, { crossDomain: true }) 
      .then(res => {      
        const propertyData = [res.data];
        propertyData.map((value) => (
          this.setState({
            investments:value
            })
        )) 
    })          
  }
  render(){            
    return (      
        <Router>        
          <div className="App">
            <Header showContactForm={this.showContactForm}  />              
            <main>            
              <Switch>
                <Route exact path="/" render={() => <Home propertyData={this.state.investments} showContactForm={this.showContactForm}/> } />
                <Route exact path="/investments" render={() => <Investments propertyData={this.state.investments} />} />
                <Route path="/investments/property/:propertyId/:propertyTitle" render={() => <InvestmentProperty propertyData={this.state.investments} showContactForm={this.showContactForm}/>}/>                            
                <Route path="/videos" component={Videos} />
                <Route path="/about" render={() => <About showContactForm={this.showContactForm} /> } />
              </Switch>
            </main>
            <Footer />
            {this.state.showContactForm  === true ? <Contact hideContactForm={this.hideContactForm} /> : null }      
          </div>
        </Router>
      
    );
  }
}

export default App;
