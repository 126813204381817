import React, { Component } from 'react';
import ContactInfo from '../../blocks/contactInfo/ContactInfo';
import SocialMediaIcons from '../../blocks/socialMediaIcons/SocialMediaIcons';
//scss css
import './_footer.scss';

class Footer extends Component {
    render(){        
        return(
            <footer>
            <div className="container">
                <SocialMediaIcons />
                <ContactInfo />
                <div>
                    <p id="copyright">Copyright © 2019 <span>JSPrealtor.com</span> <small>info@JSPrealtor.com</small></p>            
                </div>            
            </div>
            </footer>
        )
    }   
}


export default Footer;