import React, { Component } from 'react';
import Logo from '../../blocks/logo/Logo';
import Navigation from '../../blocks/navigation/Navigation';
import ContactInfo from '../../blocks/contactInfo/ContactInfo';
import SocialMediaIcons from '../../blocks/socialMediaIcons/SocialMediaIcons';
//scss css
import './_header.scss';

class Header extends Component {
    render(){        
        return(
            <>
                <header role="banner">
                    <div className="top-header">  
                        <div className="container">
                            <ContactInfo />
                            <SocialMediaIcons  />
                        </div>
                    </div>
                    <div className="header">
                        <div className="container">
                            <Logo />  
                            <Navigation showContactForm={this.props.showContactForm} />                                              
                        </div>
                    </div>
                </header>
                
            </>
        )
    }   
}


export default Header;